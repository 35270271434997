/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
:root {
  --bp-small: 480px;
  --bp-medium: 768px;
  --bp-large: 1024px;
  --bp-x-large: 1280px;
}

@keyframes patt1 {}

@media screen and (max-width: 768px) {
  .App-header {
    font-size: calc(10px + 2vmin);
    margin-top: 10px;
  }

  .App-logo {
    height: 64px;
  }
}

body {
  font-size: 18px;
}

button:hover {
  cursor: pointer;
}

.App-header {
  font-size: calc(10px + 2vmin);
  text-align: center;
  margin-top: 2vh;
}

.App-logo {
  margin: 0 auto;
}

.App-body {
  text-align: center;
  background-image: url('./imgs/top-down-business-meeting.jpg');
  background-size: cover;
  position: relative;
  color: white;
  overflow: auto;
}

.App-body h1 {
  font-size: 2rem;
}

p {
  font-size: Roboto;
}

.content-container {
  margin-bottom: 200px;
}

.footer {
  text-align: center;
  margin: 5px;
}

.modal-background {
  content: '';
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2;
  display: none;
  justify-content: center;
  align-items: center;
}

.modal-background.show {
  display: flex;
}

.invalid-feedback {
  color: red;
}